import React, {Component} from 'react';
import $ from 'jquery';
// import logo from './logo.svg';
// import './App.css';

import ViewShortUrl from '../ViewShortUrl/ViewShortUrl';
import EditShortUrl from '../EditShortUrl/EditShortUrl';

class ShortUrl extends Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({
      editing: []
    }, props.data);
  }

  componentDidMount() {
    if(this.props.editing == 'true') {
      this.state.editing[this.state.id] = true;
      this.forceUpdate()
    }
  }

  onShortUrlClick(e) {
    if($(e.target).is('a')) {
      return;
    }
    if(e) {
      e.preventDefault();
    }
    this.state.editing[this.state.id] = true;
    this.props.onShortUrlClick();
    this.forceUpdate();
  }

  onSubmit(shortUrl) {
    const self = this;

    this.setState(shortUrl);

    let method = 'post';
    if(shortUrl && parseInt(shortUrl.id) > 0)
      method = 'put';

    return $.ajax({
      url: '/api/?handler='+method+'/url',
      dataType: 'json',
      method: 'post',
      data: shortUrl
    })
    .done((data) => {
      if(! this.state.id) {
        document.location.reload();
      }
      this.state.id = data.id;
      // Hide new url form if visible.
      if(this.state.editing[this.state.id]) {
        if(self.props.onClick) {
          self.props.onClick()
        }
      }
      // Go to view mode again.
      this.state.editing[this.state.id] = false
      // Reload
      self.props.getShortUrls()
      $('.new-url-btn').removeClass('is-active')
      this.forceUpdate()
    })
    .fail((xhr, status, err) => {
      console.log(status, err);
      alert("Vul een unieke naam in\n > go.secu.nl/"+shortUrl.name+" bestaat al")
    });
  }

  onDelete(shortUrl) {
    const areYouSure = window.confirm("Weet je zeker dat je deze 'short url' wilt verwijderen?\n\nJe kunt dit niet ongedaan maken.");
    if(! areYouSure) {
      return;
    }
    return $.ajax({
      url: '/api/delete/url/'+shortUrl.id,
      dataType: 'json',
      method: 'DELETE'
    })
    .done((data) => {
      console.log('Deleted shortUrl succesful');
    })
    .fail((xhr, status, err) => {
      console.log(status, err)
    });
  }

  render() {
    const self = this;

    return <div>
      {this.state.editing[this.state.id] && 
        <EditShortUrl
          key={this.state.id}
          onSubmit={this.onSubmit.bind(this)}
          onDelete={this.onDelete.bind(this)}
          data={this.state} />}
      {! this.state.editing[this.state.id] && 
        <ViewShortUrl
          key={this.state.id}
          onClick={this.onShortUrlClick.bind(this)}
          data={this.state} />}
    </div>
  }
}

export default ShortUrl;
