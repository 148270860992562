import React, {Component} from 'react';
import $ from 'jquery';
// import logo from './logo.svg';
// import './App.css';

// import NewShortUrl from '/components/NewShortUrl/NewShortUrl';

class NewShortUrl extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  onClick() {
    this.props.onClick()
  }

  render() {

    const self = this;

    return <div onClick={this.onClick.bind(this)} className="url-shortnr-list-row new-url-btn">
      <div className="name-cell"><span>go.secu.nl/</span><span className="dashed-border">new</span></div>
      <div className="description-cell">Klik hier om een nieuwe URL toe te voegen</div>
      <div className="url-cell"><a href="https://secu.nl/actie">secu.nl/actie</a></div>
      <div className="date-cell">2015-10-12</div>
    </div>

  }
}

export default NewShortUrl;
