import React, {Component} from 'react';
import $ from 'jquery';
// import logo from './logo.svg';
// import './App.css';

// import EditShortUrl from '/components/EditShortUrl/EditShortUrl';

class EditShortUrl extends Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, props.data, {
    });
  }

  componentDidMount() {
  }

  handleChange(e) {
    const field = $(e.target).attr('name');
    this.state[field] = $(e.target)[0].value;
    this.setState(this.state);
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.state);
  }

  onDelete(e) {
    const self = this;
    e.preventDefault();

    this.props.onDelete(this.state).done((data) => {
      $('.url-shortnr-list-row[data-id='+self.state.id+']').slideUp(() => {
        document.location.reload()
      });
    });
  }

  render() {
    const self = this;
    const deleteBtn = this.state.id ? <input onClick={this.onDelete.bind(this)} type="button" value="Verwijder" /> : <div />

    return <form onSubmit={this.onSubmit.bind(this)} className="url-shortnr-list-row edit-form dashed-border" data-id={this.state.id}>
      <div className="name-cell">
        <span>go.secu.nl/</span>
        <input autoComplete="off" required="required" placeholder="korte-naam" type="text" className="name" autoFocus name="name" ref="name" value={this.state.name} onChange={this.handleChange.bind(this)} />
      </div>
      <div className="description-cell">
        <input autoComplete="off" placeholder="Gebruikt op verpakking X" type="text" value={this.state.description} className="description" name="description" ref="description" onChange={this.handleChange.bind(this)} />
      </div>
      <div className="url-cell">
        <input autoComplete="off" placeholder="Verwijst naar ... (bijv: http://secu.nl)" type="text" value={this.state.url} className="url" name="url" ref="url" onChange={this.handleChange.bind(this)} />
      </div>
      <div className="date-cell">
        <input type="hidden" name="id" value={this.state.id} ref="id" />
        <input required="required" type="hidden" name="date" value={this.state.date} ref="date" />
      </div>
      <div className="submit-cell cf">
        {deleteBtn}
        <input type="submit" value="Opslaan" />
      </div>
    </form>
  }
}

export default EditShortUrl;
