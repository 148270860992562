import React, {Component} from 'react';
import $ from 'jquery';
// import logo from './logo.svg';
// import './App.css';

// import ViewShortUrl from '/components/ViewShortUrl/ViewShortUrl';

class ViewShortUrl extends Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, props.data);
  }

  onClick() {
  }

  render() {
    const self = this;

    return <div onClick={this.props.onClick} className="url-shortnr-list-row">
      <div className="name-cell">{this.state.name}</div>
      <div className="description-cell">{this.state.description}</div>
      <div className="url-cell"><a href={this.state.url} target="_blank">{this.state.url}</a></div>
      <div className="date-cell">{this.state.date}</div>
    </div>

  }
}

export default ViewShortUrl;
