import React, {Component} from 'react';
import $ from 'jquery';
// import logo from './logo.svg';
// import './App.css';

import NewShortUrl from '../NewShortUrl/NewShortUrl';
import ShortUrl from '../ShortUrl/ShortUrl';

class UrlShortnrList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newShortUrlClicked: false,
      urls: []
    };
  }

  componentDidMount() {
    this.getShortUrls()
  }

  getShortUrls() {
    const self = this;
    return $.ajax({
      url: '/api/?handler=get/urls',
      dataType: 'json',
      method: 'get'
    })
    .done((data) => {
      self.state.urls = data;
      self.forceUpdate()
    })
    .fail((xhr, status, err) => {
      console.log(status, err);
    });
  }

  newShortUrl(e) {
    $('.new-url-btn').toggleClass('is-active');
    this.setState({
      newShortUrlClicked: ! this.state.newShortUrlClicked
    });
  }

  // On url click.
  onShortUrlClick(e) {
    this.setState({
      newShortUrlClicked: false
    });
  }

  render() {

    const self = this;

    const newShortUrl = <NewShortUrl onClick={this.newShortUrl.bind(this)}></NewShortUrl>

    let data = {}
    let editShortUrl = <div />
    if(this.state.newShortUrlClicked) {
      editShortUrl = <ShortUrl
        onShortUrlClick={self.onShortUrlClick.bind(this)}
        getShortUrls={self.getShortUrls.bind(this)}
        data={data}
        editing="true" />
    }

    const shortUrls = $.makeArray(this.state.urls).map((item, index) => {
      return <ShortUrl
        onShortUrlClick={self.onShortUrlClick.bind(this)}
        getShortUrls={self.getShortUrls.bind(this)}
        data={self.state.urls[index]}
      >{item}</ShortUrl>
    });

    return <div className="url-shortnr" width="600">
      <div className="url-shortnr-list">
        {newShortUrl}
        {editShortUrl}
        {shortUrls}
      </div>
    </div>

  }
}

export default UrlShortnrList;
