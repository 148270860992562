import React, {Component} from 'react';
import $ from 'jquery';
// import logo from './logo.svg';
// import './App.css';

import UrlShortnrList from './components/url-shortnr-list/url-shortnr-list';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const self = this;
    const slug = document.location.pathname.substr(1);

    if(slug == 'new') {
      return;
    }

    if(slug) {
      this.lookupName(slug).done((data) => {
        self.redirect()
      });
    }
  }

  lookupName(slug) {
    const self = this;

    return $.ajax({
      url: '/api/?handler=get/url/'+slug,
      dataType: 'json',
      method: 'get'
    })
    .done((data) => {
      console.log('data', data)
      self.state.url = data[0].url;
      self.forceUpdate();
    }).fail((xhr, status, err) => {
      console.log(status, err)
    });
  }

  redirect() {
    if(this.state.url) {
      document.location = this.state.url;
    }
    return;
    if(! this.state.url) {
      alert("De opgevraagde pagina bestaat niet (meer).\n\nU wordt nu automatisch doorverwezen naar secu.nl");
      document.location = 'https://www.secu.nl';
    }
  }

  render() {
    const slug = document.location.pathname.substr(1);

    return (
      <div className="App">
        {slug == 'new' && <UrlShortnrList />}
      </div>
    );
  }
}

export default App;
